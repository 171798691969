<template>
  <travio-center-container grid-width='1/2' :pageTitle="'Edit - ' + supplierName" v-if="hasLoaded">
    <vx-card>
      <div class="flex flex-wrap items-center mb-4">
        <vs-avatar 
          :src="supplierLogo"
          :text="supplierName" 
          class="supplier-logo-avatar mr-2" 
          size="60px"
        />
        <div class="ml-2"> 
          <h2 class="supplier-name-heading">{{ supplierName }} (ID: {{ threadId }})</h2>
          <p class="text-muted text-sm mt-1">{{ productType }}  &#8226;  {{ apiType }}</p>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Description*</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input class="w-full" v-model="form.description" v-validate="'required|max:50'" name="Description" />
          <span class="text-danger text-sm">{{ errors.first('Description') }}</span>
        </div>
      </div>
      <div class="vx-row mb-6" v-if="form.usernameLabel">
        <div class="vx-col sm:w-1/5 w-full">
          <span>{{form.usernameLabel}}</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input class="w-full" v-model="form.username" v-validate="'max:1250'" name="Username" />
          <span class="text-danger text-sm">{{ errors.first('Username') }}</span>
        </div>
      </div>

      <div class="vx-row mb-6" v-if="form.passwordLabel">
        <div class="vx-col sm:w-1/5 w-full">
          <span>{{form.passwordLabel}}</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input class="w-full" v-model="form.password" v-validate="'max:1250'" name="Password" />
          <span class="text-danger text-sm">{{ errors.first('Password') }}</span>
        </div>
      </div>
      
       <div class="vx-row mb-6" v-if="hasEndpoint">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Endpoint</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input class="w-full" v-model="form.endpoint" v-validate="'required|max:500'" name="Endpoint" />
          <span class="text-danger text-sm">{{ errors.first('Endpoint') }}</span>
        </div>
      </div>

      <div class="vx-row mt-4 mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Is Enabled</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-switch v-model="form.isActive" name="Is Active" />
          <span class="text-danger text-sm">{{ errors.first('Is Active') }}</span>
        </div>
      </div>

      <div class="vx-row mt-4 mb-6" v-if="isActiveAppSharingSuppliers">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Is Shared</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-switch v-model="form.isShared" name="Is Shared" />
          <span class="text-danger text-sm">{{ errors.first('Is Shared') }}</span>
        </div>
      </div>

      <vs-alert v-if="form.isCredentialInvalid" icon-pack="feather" icon="icon-alert-triangle" class="h-full my-4" color="warning">
        <span>This integration was automatically disabled on {{ formattedCredentialInvalidDate }} due to invalid credentials being detected. If you have resolved this issue, just re-save the settings to re-enable.</span>
      </vs-alert>

      <div class="mt-10">
        <h5 style="color:#636363">Additional Settings</h5>
      </div>
      <ag-grid-vue
        ref="agGridTable"
        :components="cellRendererComponents"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="settingsData"
        :gridOptions="gridOptions"
        :rowSelection="rowSelection"
        :pagination="false"
        :paginationPageSize="paginationPageSize"
        :suppressPaginationPanel="true"
        :context="context"
        :rowClassRules="rowClassRules"
        :style="{ height:'500px' }"
        :enableBrowserTooltips="true"
      >
      </ag-grid-vue>
      
      <div class="flex flex-wrap justify-end">
        <vs-button @click="handleCancel" type="border" color="danger" class="mt-4 mr-4">Cancel</vs-button>
        <vs-button @click='handleTest' color="warning" class="mt-4 mr-4">Test</vs-button>
        <vs-button @click='onSubmit' class="mt-4">Save</vs-button>
      </div>

    </vx-card>
  </travio-center-container>

</template>

<script>
import vSelect from 'vue-select'
import {AgGridVue} from "ag-grid-vue";
import 'ag-grid-community/dist/styles/ag-grid.css';
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import CellRendererIntegrationSettingsValue from './cell-renderers/CellRendererIntegrationSettingsValue.vue'
import Vue from 'vue'
import { format } from 'date-fns'

export default {
  components: {
    vSelect,
    AgGridVue,
    CellRendererIntegrationSettingsValue,
  },
  data () {
    return {
      form: {
        description: '',
        endpoint: '',
        username: '',
        password: '',
        usernameLabel: '',
        passwordLabel: '',
        isActive: false,
        isCredentialInvalid: false,
        credentialInvalidDate: null
      },
      hasEndpoint: false,
      settings: [],
      threadId: '',
      supplierLogo: '',
      supplierName: '',
      productType: '',
      productTypeId: '',
      apiEnum: '',
      apiType: '',
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      defaultColDef: null,
      rowSelection: null,
      cellRendererComponents: { CellRendererIntegrationSettingsValue },
      context: null,
      tabIndex: 0,
      rowClassRules: null,
      hasLoaded: false,
      isActiveAppSharingSuppliers: false
    }
  },
  props: {
    supplierId: { required: true },
    applicationId: { required: true },
    integrationId: { required: true },
    tabIndexProp: { required: false, default: 0, type: Number }
  },
  computed: {
    settingsData () {
      return this.settings
    },
    formattedCredentialInvalidDate () {
      return this.form.credentialInvalidDate && format( new Date(this.form.credentialInvalidDate), 'dd-MM-yyyy')
    },
    paginationPageSize () {
      if (this.gridApi) return this.gridApi.paginationGetPageSize()
      else return 10
    },
    totalPages () {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages()
      else return 0
    },
    currentPage: {
      get () {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
        else return 1
      },
      set (val) {
        this.gridApi.paginationGoToPage(val - 1)
      }
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    }
  },
   beforeMount() {
    this.gridOptions = {};

    // Vue.extend for CellRenderer is not documented, see https://github.com/ag-grid/ag-grid/issues/3575 instead
    this.columnDefs =  [
      { field: 'threadId', hide: true },
      { field: 'settingOptions', hide: true },
      { field: 'settingIsSet', hide: true },
      { field: 'settingOptionsMultiple', hide: true },
      { field: 'isDirty', hide: true },
      { field: 'settingValue', hide: true },
      { field: 'settingName', hide: true },
      { headerName: 'Setting Description', field: 'settingDescription', sortable: true, suppressSizeToFit: false, width: 295, autoHeight: true, tooltipField: 'settingDescription' },
      { headerName: 'Setting Value', 
        sortable: true,
        suppressSizeToFit: false,
        width: 450,
        autoHeight: true,
        cellRendererFramework: Vue.extend(CellRendererIntegrationSettingsValue) },
    ]

    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      sortable: true,
      autoHeight: true,
      resizable: true,
      suppressMenu: true
    }
    this.rowSelection = 'single';
    this.rowClassRules = {
      'dirty-style': 'data.isDirty',
    };
    //This will make methods from this accessible to cell renderer
    this.context = { componentParent: this }
  },
  created () {
    //Check if user has acccess to the company
    const hasAccess = this.activeUserInfo.applications && this.activeUserInfo.applications.find(x => x.id == this.applicationId)
    if(!hasAccess) {
      this.$router.push('/error-404')
      return
    }

    this.tabIndex = this.tabIndexProp
    this.$http.get(`api/supplierIntegrations/apps/${this.applicationId}/integrations/${this.integrationId}`)
    .then(response => {
      // destructure response data to remove array
      const { settings, supplierName, supplierLogo, productType, apiType, ...responseWithoutSettings } = response.data;
      if(responseWithoutSettings.applicationId != this.applicationId) {
        // Shared suppliers shouldn't be editable by other apps
        this.$router.push('/error-404')
      }
      this.form = Object.assign({}, responseWithoutSettings)
      this.settings = response.data.settings
      this.threadId = response.data.threadId
      this.supplierLogo = response.data.supplierLogo
      this.supplierName = response.data.supplierName
      this.productType = response.data.productType
      this.apiEnum = response.data.apiEnum
      this.productTypeId = response.data.productTypeId
      this.apiType = response.data.apiType
      this.hasEndpoint = responseWithoutSettings.endpoint  ? true : false
      this.isActiveAppSharingSuppliers = response.data.isAppSharingSuppliers
      this.hasLoaded = true
    })
    .catch(err => console.error(err) )
  },
  mounted () {
    this.gridApi = this.gridOptions.api;
  },
  beforeRouteLeave (to, from , next) {
    if(this.$_.find(this.settings, s => s.isDirty)) {
      const answer = window.confirm('Do you really want to leave? You have unsaved changes!')
      if (answer) {
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  },
  methods: {
    onSubmit () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$vs.loading()
          this.$http.put(
            `api/supplierintegrations/apps/${this.applicationId}/integrations/${this.integrationId}`,
            { settings: this.settings, ...this.form })
            .then(response => {
              this.$_notifySuccess('Integration successfully saved');
              // Reset dirty settings to prevent popup asking for unsaved changes
              this.settings = this.$_.map(this.settings, el => {
                el.isDirty = false
                return el;
              })
              this.$router.push({name: 'supplier-integrations-details', 
                params: { supplierId: this.supplierId, applicationId: this.applicationId }
              })
            })
            .catch(error => this.$_notifyFailureByResponseData(error.response.data))
            .finally(() => this.$vs.loading.close())
        } else {
          this.$_notifyFailure('Invalid form data. Please correct all form fields.')
        }
      })
    },
    handleCancel () {
      if(this.$_.find(this.settings, s => s.isDirty)) {
        const answer = window.confirm('Do you really want to leave? You have unsaved changes!')
        if (answer) {
          this.$router.push({ name: 'supplier-integrations-details', params: { applicationId: this.applicationId, supplierId: this.supplierId}})
        }
      } else {
        this.$router.push({ name: 'supplier-integrations-details', params: { applicationId: this.applicationId, supplierId: this.supplierId}})
      }
    },
    handleTest () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$vs.loading()
          this.$http.post(
            `https://api.travelify.io/testsupplier/${this.apiEnum}/${this.productTypeId}`,
            { settings: this.settings, ...this.form })
            .then(response => {
              if(response.data.success) {
                this.$_notifySuccess('Credentials appear to be working successfully');
              } else {
                this.$_notifyFailure(response.data.error);
              }
            })
            .catch(error => this.$_notifyFailureByResponseData(error.response.data))
            .finally(() => this.$vs.loading.close())
        } else {
          this.$_notifyFailure('Invalid form data. Please correct all form fields before testing.')
        }
      })
    },
    onSelectionChanged() {
      var selectedRows = this.gridApi.getSelectedRows();
    },
    onSettingChanged (settingName) {
      //This will be called by cell renderer
      let setting = this.$_.find(this.settings, x => x.settingName === settingName)
      this.$vs.loading()
      this.$http.put(
        `api/supplierintegrations/apps/${this.applicationId}/integrations/${this.integrationId}/settings/${settingName}`,
        { settingValue: setting.settingValue })
        .then(response => {
          this.$_notifySuccess('Setting successfully saved');
          //this is to force refresh the dirty styling
          this.settings = Object.assign({}, this.settings)
        })
        .catch(error => this.$_notifyFailureByResponseData(error.response.data))
        .finally(() => this.$vs.loading.close())
    },
    onSettingEmptyChanged (settingName) {
      //This will be called by cell renderer
      this.$_notifyFailure(settingName + ' cannot be empty.')
    },
    onDirty () {
      //this is to force refresh the dirty styling
      this.settings = [...this.settings]
    }
  }
}
</script>

<style>
  .company-logo-avatar .con-vs-avatar,
  .company-logo-avatar .vs-avatar--con-img {
    border-radius: 10%;
  }
  .dirty-style:not(.vs-button) {
    color: red !important
  }
</style>
